import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";



const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import('../views/Callback.vue')
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('../views/Register.vue')
    // },
    {
        path: '/confirmUserInvitation',
        name: 'confirmUserInvitation',
        component: () => import('../views/ConfirmUserInvitation.vue')
    },
    {
        path: '/confirmEmail',
        name: 'confirmEmail',
        component: () => import('../views/ConfirmEmail.vue')
    },
    {
        path: '/registrationComplete',
        name: 'registrationComplete',
        component: () => import('../views/RegistrationComplete.vue')
    }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes
});

export default router;
