import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from '../src/router/index'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(pinia);
app.use(PrimeVue, {
    unstyled: true
});
app.use(ToastService);
app.mount('#app');
